<script setup lang="ts">
const modalStore = useModalStore()
const { makeAWishBanner } = storeToRefs(modalStore)

const status = ref<'welcome' | 'form' | 'wishMade'>('welcome')
</script>

<template>
	<ClientOnly>
		<SpDialog
			v-model="makeAWishBanner"
			:custom-options="{
				dialogContainer: 'lg:rounded-[40px] lg:w-[800px]  max-h-[calc(100dvh-60px)]',
				dialogBodyContainer: '!p-4 lg:!p-8',
			}"
			teleport-to="#dialogs-container"
			@close="[(makeAWishBanner = false), (status = 'welcome')]"
			:lock-body-scroll="false"
		>
			<div class="relative w-full overflow-y-scroll rounded-[20px] bg-secondary-gray-50">
				<MakeWishWelcomeBanner
					v-if="status == 'welcome'"
					@close="[(makeAWishBanner = false), (status = 'welcome')]"
					@make-wish="status = 'form'"
				></MakeWishWelcomeBanner>

				<MakeWishForm
					v-else-if="status == 'form'"
					@close="[(makeAWishBanner = false), (status = 'welcome')]"
					@wish-made="status = 'wishMade'"
					@back-to-welcome="status = 'welcome'"
				></MakeWishForm>

				<MakeWishSentWishBanner
					v-else-if="status == 'wishMade'"
					@close="[(makeAWishBanner = false), (status = 'welcome')]"
					@make-another-wish="status = 'form'"
				></MakeWishSentWishBanner>
			</div>
		</SpDialog>
	</ClientOnly>
</template>

<style>
.bg-make-wish-image {
	background-image: url('~/assets/images/make-a-wish-background.png');
	background-size: cover;
	background-position: center;
}
</style>
